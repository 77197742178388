import $ from 'jquery';
import _ from 'lodash';
import animejs from 'animejs';

window.jQuery = $;

$(()=>{

    $.extend( true, __graphData, {

        "tooltip": {
            "backgroundColor": 'black',
            "borderColor": 'black',
            "style":{
                "color": 'white'
            },
            "useHTML":true,
            "formatter": function() {

                var isAttackStr = "";
                if ( !_.isNil(this.point.is_attack) ) {
                    if ( this.point.is_attack === true ) 
                        isAttackStr = "<span style='font-weight:normal'>&nbsp;&nbsp;|&nbsp;&nbsp; Ataque</span>";
                    else
                        isAttackStr = "<span style='font-weight:normal'>&nbsp;&nbsp;|&nbsp;&nbsp; Defensa</span>";
                }

                var str = '<span class="toolip">'+
                    '<span class="tooltip-header">' + this.point.name + isAttackStr +'</span>';
                  
                str += '<span class="toolip-body">';

                // console.log("info: ",this.point);

                var percentageStr = "";
                if ( !_.isNil(this.point.percentage) && 
                    this.point.percentage === true ) percentageStr = "%";

                if ( !_.isNil(this.point.attack) && !_.isNil(this.point.defence) ) {  

                    if ( !_.isNil(this.point.tweets) ) {  
                        str += '<span class="tooltip-icon tooltip-icon-twitter"></span>&nbsp;'+
                            this.point.tweets +percentageStr+ '&nbsp;&nbsp;&nbsp;';
                    }

                    if ( !_.isNil(this.point.time) ) {  
                        str += '<span class="tooltip-icon tooltip-icon-time"></span>&nbsp;'+
                        this.point.time+percentageStr;
                    }

                    // str += '<span class="hr"></span>';
                    str += '<span class="stats">';

                    str += 'Ataque: '+ this.point.attack + 
                        ( (parseFloat(this.point.attack) > 1 )?' TRENDS':'TREND' )+
                        ' , ' + this.point.attackTime + 
                        'H' + '&nbsp;&nbsp;&nbsp;';

                    str += '<br>Defensa: '+ this.point.defence + 
                        ( (parseFloat(this.point.defence) > 1 )?' TRENDS':'TREND' )+
                        ' , ' + this.point.defenceTime + 'H';
                    
                    str += '</span >';
                }
                else {

                    if ( !_.isNil(this.point.tweets) ) {  
                        str += '<span class="tooltip-icon tooltip-icon-twitter"></span>&nbsp;'+
                            this.point.tweets +percentageStr+ '&nbsp;&nbsp;&nbsp;';
                    }

                    if ( !_.isNil(this.point.trend) ) {  
                        str += '<span class="tooltip-icon tooltip-icon-trends"></span>&nbsp;'+
                            this.point.trend +percentageStr+ '&nbsp;&nbsp;&nbsp;';
                    }

                    if ( !_.isNil(this.point.time) ) {  
                        str += '<span class="tooltip-icon tooltip-icon-time"></span>&nbsp;'+
                        this.point.time+percentageStr;
                    }
                }

                str += '</span></span>';

                return str;
            }
        }        
    });    

    // console.log('__graphData', __graphData);
    window.graph = new Graph( __graphData );

    // $(window).on('resize', () => {
    //     console.log('resize');

    //     const w = window.innerWidth;

    //     if ( w <= 639 ) {
    //         if ( chart && chart.series ) {
    //             chart.series[0].update({
    //                 layoutAlgorithm: 'stripes'
    //             });
    //         }
    //     } else {
    //         if ( chart && chart.series ) {
    //             chart.series[0].update({
    //                 layoutAlgorithm: 'squarified'
    //             });
    //         }
    //     }
    // });

});

class Graph {

    constructor ( data ) {
        this.__data = data;
        this.chart = null;
        this.filters = {};

        this.SORT_MODE_TWEETS = 0;
        this.SORT_MODE_TIME = 1;
        this.currentSortMode = this.SORT_MODE_TWEETS;

        this.originalData = this.configureDataForTweets( data );
        this.chart = new Highcharts.Chart( this.originalData );
    }

    configureDataForTweets( data ) {
        return this.configureDataForProp( data,'tweets');
    }

    configureDataForTime( data ) {
        return this.configureDataForProp( data, 'time');
    }

    configureDataForProp( data, prop ) {
        let nData = _.cloneDeep( data );

        // DRILLDOWN
        if ( 
            !_.isNil(nData.drilldown) && 
            !_.isNil(nData.drilldown.series) && 
            _.isArray(nData.drilldown.series)  
        ) {
            for( var i = 0; i < nData.drilldown.series.length; i++ ){
                let data =  nData.drilldown.series[i].data;

                for( var j = 0; j < data.length; j++ ){
                    let item = data[j];
                    item.value = item[prop];

                    if ( prop == 'time' ) {                        
                        item['color'] = item['colorByTweets'];

                        if ( item['fontSizeByTime'] > 10  )
                            item['dataLabels'] = { "style": { "fontSize": item['fontSizeByTime'] }};
                        // else
                        //     item['dataLabels'] = { "enabled": false };                 

                    }
                    else if ( prop == 'tweets' ) {
                        item['color'] = item['colorByTime'];

                        if ( item['fontSizeByTweets'] > 10  )
                            item['dataLabels'] = { "style": { "fontSize": item['fontSizeByTweets'] }};
                        // else
                        //     item['dataLabels'] = { "enabled": false };                 
                    }

                    data[j] = item;
                }

                nData.drilldown.series[i].data = data;
            }
        }

        // MAIN SERIES
        if ( nData.series ) {
            for( var i = 0; i < nData.series.length; i++ ){
                let data =  nData.series[i].data;

                for( var j = 0; j < data.length; j++ ){
                    let item = data[j];

                    this.filters[item.id] = true;

                    item.value = item[prop];
                    item.name = item.name + ' ' + item.value + '%';

                    data[j] = item;
                }

                nData.series[i].data = data;
            }
        }  

        return nData;      
    }

    setVisibilityParentData( parentId, visible ) {

        if ( _.isNil(parentId) ) return;
        const self = this;

        let nData = _.cloneDeep(this.originalData);
        const $treemapContainer = $('#container-treemap');

        parentId =  parentId.toLowerCase();
        this.filters[parentId] = visible;

        // console.log('setVisibilityParentData: parentId:',parentId, ' visible:', visible, this.filters);

        // DRILLDOWN
        if ( 
            !_.isNil(nData.drilldown) && 
            !_.isNil(nData.drilldown.series) && 
            _.isArray(nData.drilldown.series)  
        ) {
            for( var i = 0; i < nData.drilldown.series.length; i++ ){
                let data =  nData.drilldown.series[i].data;

                for( var j = 0; j < data.length; j++ ){
                    let item = data[j];
                    if ( _.isNil(item) ) {
                        // console.log('borrado1: i: ' +i+', j:' + j);
                        continue;
                    }

                    let id = !_.isNil(item['id']) ? String(item['id']).toLowerCase():false;
                    let parentId = !_.isNil(item['parent']) ? String(item['parent']).toLowerCase():false;

                    if ( !( ( id &&  self.filters[id] === true ) || 
                        ( parentId && self.filters[parentId] === true ) )
                    ) {
                        data[j] = null;
                        delete data[j];
                    }
                }

                data = data.filter(function(){return true;});
                nData.drilldown.series[i].data = data;
            }
        }

        // MAIN SERIES
        if ( nData.series ) {
            for( var i = 0; i < nData.series.length; i++ ){
                let data =  nData.series[i].data;

                for( var j = 0; j < data.length; j++ ){
                    let item = data[j];
                    if ( _.isNil(item)) {                        
                        continue;
                    }

                    let id = !_.isNil(item['id']) ? String(item['id']).toLowerCase():false;
                    let parentId = !_.isNil(item['parent']) ? String(item['parent']).toLowerCase():false;

                    if ( !( ( id &&  self.filters[id] === true ) || 
                        ( parentId && self.filters[parentId] === true ) )
                    ) {
                        data[j] = null;
                        delete data[j];
                    }
                }

                data = data.filter(function(){return true;});
                nData.series[i].data = data;
            }
        }  

        // console.log('nData:', nData);
        animejs({
            targets:$treemapContainer.get(0),
            opacity:0,
            duration:300,
            complete:()=> {
                self.chart.destroy();
                self.chart = null;
                $treemapContainer.html('');
                // this.chart.update( nData, true , true, true); 
                self.chart = new Highcharts.Chart( nData );

                animejs({
                    targets:$treemapContainer.get(0),
                    opacity:1,
                    duration:300,
                });
            }
        });
    }

    changeSortToTweetsMode() {
        this.changeSortMode(this.SORT_MODE_TWEETS);
    }

    changeSortToTimeMode() {
        this.changeSortMode(this.SORT_MODE_TIME);
    }

    changeSortMode( mode ){
        const self = this;
        if ( mode != self.SORT_MODE_TWEETS && mode != self.SORT_MODE_TIME ) return;
        if ( self.currentSortMode == mode ) return;

        self.currentSortMode = mode;
        const $treemapContainer = $('#container-treemap');
        if ( mode == self.SORT_MODE_TWEETS ) 
            self.originalData =  self.configureDataForTweets(self.__data);
        else
            self.originalData =  self.configureDataForTime(self.__data);

        animejs({
            targets:$treemapContainer.get(0),
            opacity:0,
            duration:300,
            complete:()=> {
                self.chart.destroy();
                self.chart = null;
                $treemapContainer.html('');
                self.chart = new Highcharts.Chart( self.originalData );

                animejs({
                    targets:$treemapContainer.get(0),
                    opacity:1,
                    duration:300,
                });
            }
        });        
    }


}
