import $ from 'jquery';
import _ from 'lodash';
import animejs from 'animejs';

window.jQuery = $;

$(()=>{

	const $mainMenuBtn = $('#menu-item-slider-button');	
	const $sideBar = $('#custom-sidebar');	
	const $sideBarContent = $sideBar.find('.content');	
	const $sideBarBg = $sideBar.find('.bg');	
	const $sideBarCloseBtn = $sideBar.find('.btn-close');		

	const $optionsBtn = $('#btn-options');		

	var isLoading = false;

	// OPENING MENU
	$mainMenuBtn.on('click', () => {

		const $accordion = $sideBarContent.find('.accordion');
		animejs({
			targets:$accordion.get(0),
			scrollTop: 0.0,
			duration:400
		});

		const w = $sideBarContent.width();

		$sideBar.show();
		animejs.set(
			$sideBar.get(0), {
			'translateX': 50,
			'display':'block',
			'opacity':0,
		});
		animejs({
			'targets': $sideBar.get(0),
			'translateX': 0,
			'easing':'easeOutQuart',
			'duration':300,
			'opacity':1,
		});	

		animejs.set(
			$sideBarCloseBtn.get(0), {
			'translateX': 20,
			'opacity':0,
		});
		animejs({
			targets: $sideBarCloseBtn.get(0),
			'translateX': 0,
			'easing':'easeOutQuart',
			'opacity':1,
			'delay': 100,
			'duration':'100',
		});		
	});

	// BLACK BAR OPTIONS BUTTON
	$optionsBtn.on('click', () => {
		const $accordion = $sideBarContent.find('.accordion');
		animejs({
			targets:$accordion.get(0),
			scrollTop: 0.0,
			duration:400
		});

		$accordion.find('.accordion-item[data-accordion-item]').each( function(){
			const $this = $(this);
			const $title = $this.find('.accordion-title');
			const $content = $this.find('.accordion-content');
			const id = $this.data('name');

			if ( id == "options" ) {
				$this.addClass('is-active');
				$title.attr('aria-expanded', 'true');
				$content.attr('aria-hidden', 'false').show();
			} else {
				$this.removeClass('is-active');
				$title.attr('aria-expanded', 'false');
				$content.attr('aria-hidden', 'true').hide();
			} 
		});

		const w = $sideBarContent.width();

		$sideBar.show();
		animejs.set(
			$sideBar.get(0), {
			'translateX': 50,
			'display':'block',
			'opacity':0,
		});
		animejs({
			'targets': $sideBar.get(0),
			'translateX': 0,
			'easing':'easeOutQuart',
			'duration':300,
			'opacity':1,
		});	

		animejs.set(
			$sideBarCloseBtn.get(0), {
			'translateX': 20,
			'opacity':0,
		});
		animejs({
			targets: $sideBarCloseBtn.get(0),
			'translateX': 0,
			'easing':'easeOutQuart',
			'opacity':1,
			'delay': 100,
			'duration':'100',
		});		
	});

	// OPEN: RESET SCROLL
	$('.accordion-title').on('click', () => {

		const $accordion = $sideBarContent.find('.accordion');
		animejs({
			targets:$accordion.get(0),
			scrollTop: 0.0,
			duration:400
		});
	});

	// MENU CLOSE BUTTON
	var menuCloseCallback = function(ev){
		// console.log('mainMenuBtn click', ev);
		ev.preventDefault();
		animejs({
			'targets': $sideBar.get(0),
			'translateX': 50,
			'easing':'easeOutQuart',
			'opacity':0,
			'duration':200,
			'complete': () => {
				$sideBar.hide();
			}
		});		
	};
	$sideBarCloseBtn.on( 'click', (ev)=>{ menuCloseCallback(ev); });
	$sideBarBg.on( 'click', (ev)=>{ menuCloseCallback(ev); });

	// FILTERS CALLBACK ON/OFF
	$('body').on('click', '.options-list a.btn-option, .horizontal-options-list a.btn-option', function(ev){

		if ( isLoading )  return;
		if ( !window.graph ) return;

		ev.preventDefault();
		isLoading = true;

		const $this = $(this);
		const filterId = $this.attr('data-filter');
		const isActive = !$this.hasClass('unchecked');

		var $buttons = $("a[data-filter='"+filterId+"']");

		window.graph.setVisibilityParentData( filterId, !isActive );
		if ( isActive ) {
			$buttons.addClass('unchecked');
		} else {
			$buttons.removeClass('unchecked');
		}	
		isLoading = false;		
	});


	// SORT MODE TWEETS OR TIME
	$('#btn-sort-mode-tweet').on('click', function(ev){
		console.log('btn-sort-mode-tweet');
		const $this = $(this);
		if ( $this.hasClass('active') ) return;

		if ( isLoading )  return;
		if ( !window.graph ) return;
		isLoading = true;

		$this.parent().find('.menu-view-icon').each(function(){
			const $that = $(this);
			if ( $that.hasClass('twitter-icon') ) $that.addClass('active');
			else $that.removeClass('active');
		});

		window.graph.changeSortToTweetsMode();

		isLoading = false;
	});

	$('#btn-sort-mode-time').on('click', function(ev){
		console.log('btn-sort-mode-time');
		const $this = $(this);
		if ( $this.hasClass('active') ) return;

		if ( isLoading )  return;
		if ( !window.graph ) return;
		isLoading = true;

		$this.parent().find('.menu-view-icon').each(function(){
			const $that = $(this);
			if ( $that.hasClass('time-icon') ) $that.addClass('active');
			else $that.removeClass('active');
		});
		window.graph.changeSortToTimeMode();

		isLoading = false;
	});
	// 
	// 	INFO WINDOW
	const $infoWindow = $('#info-window');	
	const $infoWindowCloseBtn = $infoWindow.find('.btn-close');	
	
	// OPEN
	$('body').on('click', 'a[data-info-window]', function( ev ){

		const $this = $(this);
		const contentID = $this.attr('data-info-window');
		const contentList = {
			'faq':{
				'title':'Preguntas Frecuentes',
			}
		};

		const $contentContainer = $infoWindow.find('#content-' + contentID);
		if ( _.isNil(contentList[contentID]) || $contentContainer.length <= 0
		) return;

		$infoWindow.find('.content > div').each( function(){
			var $that = $(this);
			if ( $that.attr('id') == 'content-' + contentID ) $that.show();
			else $that.hide();
		});

		$infoWindow.find('.header-title').html( contentList[contentID]['title'] );

		$infoWindow.show();
		const w = window.innerWidth;
		animejs.set( $infoWindow.get(0), {
			'translateX': w+10,
			'display':'fixed',
		});
		animejs({
			targets: $infoWindow.get(0),
			'translateX': 0,
			'easing':'easeOutQuart',
			'duration': 400,
		});
	});

	// CLOSE
	$infoWindow.on('click', 'a.btn-close', ()=>{
		const w = window.innerWidth;
		animejs({
			targets: $infoWindow.get(0),
			'translateX': w+10,
			'easing':'easeOutQuart',
			'duration': 300,
			complete: () => {
				$infoWindow.hide();
			}
		});		
	});
	
 });